import styled from 'styled-components';

export const ContainerReferecia = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  min-height: 60px;
  padding: 10px 20px;
  border: 2px solid #dfe1e6;
  border-radius: 3px;
  justify-content: start;
  align-items: center;
  gap: 5px 5px;
`;

export const Table = styled.div`
  .table-exibition {
    .itensTable {
      svg {
        font-size: 24px;
      }
    }

    .td-style {
      white-space: nowrap;
    }
    .editado {
      color: #48778c !important;
      background: #dcfcfb !important;
    }

    .table tbody {
      border: 1px solid #dee2e6 !important;
    }
    .table tbody tr td {
      color: #757575;
      width: 20%;
      border-left: 1px solid #dee2e6;
    }
    .table tbody tr td input {
      width: 100%;
      height: 100%;
      border: none;
      box-sizing: border-box;
      &:disabled {
        border: none !important;
        background-color: transparent !important;
      }
    }
    .table thead tr th {
      width: 20%;
      padding-left: 0px;
      border: none;
      font-size: 0.8571428571428571em;
      font-style: inherit;
      color: #8850bf;
      font-weight: 600 !important;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }
  }
`;
