import React from 'react';
import { FormFinanceiro } from './FormFinanceiro';
import { FormParcelas } from './FormParcelas';
import { TableFinanceiro } from './Table';

export const Financeiro: React.FC = () => {
  return (
    <>
      <FormParcelas />
      <TableFinanceiro />
      <FormFinanceiro />
    </>
  );
};
