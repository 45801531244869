import { differenceInDays, format, isBefore, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import {
  InputAsyncSelect,
  InputDate,
  InputMoney,
} from '~/components/NovosInputs';
import { useEmissaoNFE } from '~/pages/EmissaoNFE/EmissaoNFEContext';

import clearForm from './clearForm.json';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import Separator from '~/components/Separator';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '~/services/api';
import { toast } from 'react-toastify';
import { CalcDataCondicao } from '~/pages/DevolucaoESaidaNFDeFornecedor/functions/Procedures';
import { deleteItens, insertOrUpdate } from '~/utils/masterDetail';
import { nanoid } from 'nanoid';
import { ButtonItens } from '~/pages/NFOutrasOperacoes/screens/Nfe/styles';
import { FaEraser, FaSync } from 'react-icons/fa';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoMdAddCircleOutline } from 'react-icons/io';

const MySwal = withReactContent(Swal);

export const FormParcelas: React.FC = () => {
  const [numParcela, setNumParcela] = useState(1);
  const [datasNaoInformadas, setDatasNaoInformadas] = useState<boolean>(false);

  const {
    codLoja,
    formNFE: { getValues },
    setCurrentTab,
    produtos,
    parcelasFinanceiro,
    setParcelasFinanceiro,
    isFormFinanceiroEditing,
    setIsFormFinanceiroEditing,
    isUpdate,
    formFinanceiro: {
      register,
      handleSubmit,
      control,
      reset,
      setValue,
      formState: formStateFinanceiro,
      watch,
      clearErrors: clearErrorsFormFinanceiro,
      setError,
    },
    subTotais,
    setMasterDetail,
    masterDetail,
  } = useEmissaoNFE();

  const finalizadora = watch('finalizadora');

  useEffect(() => {
    if (parcelasFinanceiro.length !== 0) {
      const totalParcelas = parcelasFinanceiro.reduce(
        (acc, parcela) => acc + parcela.val_parcela,
        0,
      );
      const resultado = subTotais.totalNF - totalParcelas;
      setValue(
        'val_financeiro',
        formatCurrencyAsText(resultado > 0 ? resultado : 0),
      );
    } else {
      setValue('val_financeiro', formatCurrencyAsText(subTotais.totalNF));
    }
  }, [parcelasFinanceiro, subTotais.totalNF]);
  const handleClearForm = useCallback(() => {
    reset(clearForm);
    setIsFormFinanceiroEditing({ isEdit: false, uuid: null });
  }, [reset]);

  const calcularDiasEntreDatas = useCallback(
    (dtaVencimento: string, dtaEmissao: string): number => {
      if (!dtaVencimento || !dtaEmissao) return 0;

      const dataVencimento = parseISO(dtaVencimento);
      const dataEmissao = parseISO(dtaEmissao);

      return differenceInDays(dataVencimento, dataEmissao);
    },
    [],
  );

  const validarDatas = useCallback(
    (dtaVencimento: string, dtaEmissao: string): boolean => {
      if (!dtaVencimento || !dtaEmissao) return false;

      const dataVencimento = parseISO(dtaVencimento);
      const dataEmissao = parseISO(dtaEmissao);

      return !isBefore(dataVencimento, dataEmissao);
    },
    [],
  );

  const handleAddParcela = handleSubmit(
    async (data: any) => {
      const dataEmissao = getValues('dta_emissao');
      const dataEntrada = getValues('dta_saida');

      if (dataEmissao === '' || dataEntrada === '') {
        setDatasNaoInformadas(true);
      }

      if (!validarDatas(data.dta_vencimento, dataEmissao)) {
        setError('dta_vencimento', { type: 'required' }, { shouldFocus: true });
        toast.warning(
          'A data de vencimento não pode ser anterior à data de emissão.',
        );
        return;
      }

      const exists = parcelasFinanceiro.filter(
        (parcela) =>
          parcela.num_condicao === data.num_condicao &&
          parcela.cod_condicao === data.condicao.value &&
          parcela.cod_finalizadora === data.finalizadora.value &&
          parcela.uuid !== isFormFinanceiroEditing.uuid,
      );
      if (exists.length > 0) {
        return toast.warning('Já existe uma condição com essas informações.');
      }

      const formatedParcela: any = {
        uuid: isFormFinanceiroEditing.isEdit
          ? isFormFinanceiroEditing.uuid
          : nanoid(),
        id: isFormFinanceiroEditing.isEdit
          ? isFormFinanceiroEditing.uuid
          : nanoid(),
        num_condicao: calcularDiasEntreDatas(data.dta_vencimento, dataEmissao),
        cod_condicao: 2,
        des_condicao: 'DD - DIAS DA DATA',
        dta_vencimento: data.dta_vencimento,
        val_parcela: transformAsCurrency(data.val_financeiro),
        cod_finalizadora: data.finalizadora.value,
        des_finalizadora: data.finalizadora.label,
        num_registro: numParcela,
      };
      setNumParcela(numParcela + 1);
      const parcelasDetail: any[] = await insertOrUpdate(
        'financeiro',
        formatedParcela,
        masterDetail,
        setMasterDetail,
      );
      setParcelasFinanceiro(parcelasDetail);
      handleClearForm();
    },
    (err) => {
      console.log(err);
    },
  );

  const validation = useCallback(async () => {
    const cod_pessoa = getValues('busca_parceiro')?.value;
    const dataEmissao = getValues('dta_emissao');
    const dataSaida = getValues('dta_saida');

    if (cod_pessoa === undefined || cod_pessoa === '') {
      toast.warning('Fornecedor deve ser selecionado');
      return true;
    }
    if (dataEmissao === '' || dataEmissao === undefined) {
      toast.warning('Data de Emissão deve ser informada.');
      return true;
    }

    if (dataSaida === '') {
      toast.warning('Data de Saída deve ser informada');
      return true;
    }
    if (produtos.length === 0) {
      toast.warning('Item(s) da NF deve(m) ser informado');
      return true;
    }
    return false;
  }, [getValues, produtos]);

  const removeParcelasRecalculo = () => {
    const parcelasFind = masterDetail.find(
      (item) => item.obj_name === 'financeiro',
    );
    parcelasFind?.itens.insert.forEach((parcela) => {
      deleteItens('financeiro', parcela.uuid, masterDetail, setMasterDetail);
    });
  };

  const ajustarArrayRecalculoFinanceiro = useCallback((data: any[]) => {
    return data.map((item) => ({
      ...item,
      des_condicao: `${item.des_definicao} - ${item.des_condicao}`,
      des_finalizadora: `${item.cod_finalizadora} - ${item.des_finalizadora}`,
    }));
  }, []);

  const handleRecalculaFinanceiro = async () => {
    const isInValid = await validation();
    if (isInValid) return;
    const cod_pessoa = getValues('busca_parceiro')?.value;
    const dta_emissao = getValues('dta_emissao');
    const dta_saida = getValues('dta_saida');
    let realizaBuscaParcelas = true;
    if (parcelasFinanceiro.length !== 0) {
      await MySwal.fire({
        title:
          'Deseja pesquisar novamente as condições de pagamento deste cliente/perfil?',
        text: 'As parcelas atuais poderão sofrer alteração de prazos, formas de pagamento e valores.',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          realizaBuscaParcelas = true;
        } else {
          realizaBuscaParcelas = false;
        }
      });
    }
    if (realizaBuscaParcelas) {
      try {
        const res = await api.get(
          `/emissao-nfe/financeiro/fornecedor/${cod_pessoa}`,
        );
        const { data, success } = res.data;

        if (success) {
          if (data.length <= 0) {
            const {
              cod_perfil: { cod_cc, cod_categoria, cod_perfil },
            } = getValues();
            const apiResponse = await api.get(`/busca-parcelas/${cod_perfil}`);
            const { data: dataReponse } = apiResponse.data;
            if (dataReponse.length > 0) {
              const valParcela =
                dataReponse.length <= 0
                  ? 0
                  : subTotais.totalNF / dataReponse.length;
              removeParcelasRecalculo();
              dataReponse.map(async (item: any, index: number) => {
                const dtavencimento = CalcDataCondicao(
                  cod_cc,
                  dta_emissao,
                  dta_saida,
                  item.num_condicao,
                  0,
                );
                const uuid = nanoid();

                const calculadoParcela = {
                  uuid,
                  id: uuid,
                  num_registro: index + 1,
                  num_condicao: item.num_condicao,
                  cod_condicao: item.cod_condicao,
                  des_condicao: item.des_condicao,
                  des_definicao: item.des_definicao,
                  val_parcela: transformAsCurrency(
                    Number(valParcela.toFixed(2)),
                  ),
                  cod_finalizadora: item.cod_finalizadora,
                  des_finalizadora: item.des_finalizadora,
                  cod_cc,
                  cod_categoria,
                  dta_vencimento: `${format(
                    new Date(dtavencimento),
                    'yyyy-MM-dd',
                  )}`,
                  flg_quitado: false,
                };

                const parcelasDetail: any[] = await insertOrUpdate(
                  'financeiro',
                  calculadoParcela,
                  masterDetail,
                  setMasterDetail,
                );
                setParcelasFinanceiro(
                  ajustarArrayRecalculoFinanceiro(parcelasDetail),
                );
              });
              return;
            }
            Swal.fire({
              text: `Nenhuma condição de Pagamento localizada para esse parceiro/perfil`,
              icon: 'info',
              showConfirmButton: false,
              timer: 1500,
            });
            return;
          }
          const { cod_cc, cod_categoria } = getValues('cod_perfil');
          const valParcela =
            data.length <= 0 ? 0 : subTotais.totalNF / data.length;
          removeParcelasRecalculo();
          data.map(async (item: any, index: number) => {
            const dtavencimento = CalcDataCondicao(
              cod_cc,
              dta_emissao,
              dta_saida,
              item.num_condicao,
              0,
            );
            const uuid = nanoid();

            const calculadoParcela = {
              uuid,
              id: uuid,
              num_registro: index + 1,
              num_condicao: item.num_condicao,
              cod_condicao: item.cod_condicao,
              des_condicao: item.des_condicao,
              des_definicao: item.des_definicao,
              val_parcela: transformAsCurrency(Number(valParcela.toFixed(2))),
              cod_finalizadora: item.cod_finalizadora,
              des_finalizadora: item.des_finalizadora,
              cod_cc,
              cod_categoria,
              dta_vencimento: `${format(
                new Date(dtavencimento),
                'yyyy-MM-dd',
              )}`,
              flg_quitado: false,
            };

            const parcelasDetail: any[] = await insertOrUpdate(
              'financeiro',
              calculadoParcela,
              masterDetail,
              setMasterDetail,
            );
            setParcelasFinanceiro(
              ajustarArrayRecalculoFinanceiro(parcelasDetail),
            );
          });
        }
      } catch (error: any) {
        if (error.data && error.data.message) {
          toast.error(error.data.message);
          return;
        }
        toast.error(String(error));
      }
    }
  };

  useEffect(() => {
    if (
      finalizadora &&
      (getValues('dta_emissao') === '' || getValues('dta_saida') === '')
    ) {
      setDatasNaoInformadas(true);
    }
  }, [finalizadora, getValues]);

  return (
    <>
      <Row>
        <Col md={10} sm={12}>
          <Separator labelText="Financeiro" sidePadding="0 0" />
        </Col>
        <Col md={2} sm={12}>
          <ButtonItens
            type="button"
            onClick={() => handleRecalculaFinanceiro()}
          >
            <FaSync color="#28a745" />
            <span>(Re) Calcular</span>
          </ButtonItens>
        </Col>
      </Row>
      <Row className="d-flex align-items-center position-relative">
        <Col sm={12} md={4}>
          <InputAsyncSelect
            label="Finalizadora"
            maxLength={50}
            placeholder="Selecione..."
            name="finalizadora"
            register={register}
            isError={!!formStateFinanceiro.errors.finalizadora}
            control={control}
            disabled={isUpdate}
            listWidth="300px"
            changeSelected={(selected) => {
              clearErrorsFormFinanceiro('finalizadora');
              setValue('finalizadora', selected);
            }}
            api={{
              route: '/finalizadora',
              method: 'get',
              bodyParams: {
                codLoja,
              },
              fields: ['cod_finalizadora', 'des_finalizadora'],
              dependsOf: ['codLoja'],
              messageForDependsOf:
                'É necessário selecionar a loja na capa da NF',
              searchBeforeFilter: true,
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputDate
            register={register}
            label="Vencimento"
            name="dta_vencimento"
            control={control}
            disabled={isUpdate}
            isError={!!formStateFinanceiro.errors.dta_vencimento}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Valor"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_financeiro"
            register={register}
            disabled={isUpdate}
            isError={!!formStateFinanceiro.errors.val_financeiro}
          />
        </Col>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={handleAddParcela}
            label="Adicionar"
            icon={IoMdAddCircleOutline}
            disabled={isUpdate}
            variant="confirm"
            width="100%"
          />
        </Col>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={handleClearForm}
            label="Limpar"
            icon={FaEraser}
            disabled={isUpdate}
            variant="clear"
            width="100%"
          />
        </Col>
        <Col sm={12} style={{ marginTop: '15px' }}>
          {datasNaoInformadas === true && (
            <Alert variant="warning">
              Para o cálculo automático do vencimento é necessário{' '}
              <b
                onClick={() => {
                  setCurrentTab('nfe');
                }}
                style={{ cursor: 'pointer' }}
              >
                informar
              </b>{' '}
              as datas de emissão e saída da NF.
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );
};
