import { DataGrid, GridColumns } from '@material-ui/data-grid';
import React, { useCallback, useState } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { InputText } from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { useEmissaoNFE } from '../../EmissaoNFEContext';
import { Button } from 'react-bootstrap';
import { AiFillEye, AiOutlineDownload } from 'react-icons/ai';
import { Table } from './styles';
import moment from 'moment';
import api from '~/services/api';
import LinearProgress from '@material-ui/core/LinearProgress';

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/webpack-resolver';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

export const Log: React.FC = () => {
  const {
    formNFE: { register, formState, getValues },
    logs,
    isUpdate,
    existeLogAcbr,
  } = useEmissaoNFE();
  const [acbrLog, setAcbrLog] = useState<any>();
  const [showLogReader, setShowLogReader] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const tabColumns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'dta_cadastro',
      headerName: 'Data de Envio',
      width: 180,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'cod_status',
      headerName: 'Status',
      width: 110,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'motivo',
      headerName: 'Mensagem',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: any) => {
        const { row } = params;
        return <span title={`${row.motivo}`}>{row.motivo}</span>;
      },
    },
    {
      field: 'recibo',
      headerName: 'Recibo',
      width: 200,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'protocolo',
      headerName: 'Protocolo',
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: any) => {
        const { row } = params;
        return <span title={`${row.protocolo}`}>{row.protocolo}</span>;
      },
    },
  ];

  const downloadAcbrLog = useCallback(
    async (fileName: string, isVisualization: boolean) => {
      setLoading(true);
      try {
        const { loja, cod_seq_nf } = getValues();
        const response = await api.get(
          `/transmitir-nfe/download-acbr-log/${loja}/${cod_seq_nf}/${fileName}`,
        );
        if (isVisualization && response.data) {
          setAcbrLog(response.data);
          setShowLogReader(true);
          setLoading(false);
          return;
        }

        if (!isVisualization) {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'text/plain' }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          // Verifica se o link tem um parentNode antes de tentar removê-lo
          if (link.parentNode) {
            link.parentNode.removeChild(link);
          }
          window.URL.revokeObjectURL(url);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [getValues],
  );

  return (
    <>
      {existeLogAcbr.logFound && (
        <Table>
          <Separator labelText="Log's do Servidor" sidePadding="0 0" />
          <Row>
            <div className="col-sm-12 table-exibition">
              <table className="table">
                <thead>
                  <tr>
                    <th>Arquivo</th>
                    <th>Data</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {existeLogAcbr.logs.map((logFile) => {
                    return (
                      <tr>
                        <td>{logFile.fileName}</td>
                        <td>
                          {moment(
                            createDateWithoutTimezone(logFile.date),
                          ).format('DD/MM/YYYY')}
                        </td>
                        <td
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '3px',
                          }}
                        >
                          <Button
                            onClick={() =>
                              downloadAcbrLog(logFile.fileName, false)
                            }
                            title="download"
                            variant="primary"
                            size="sm"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '3px',
                            }}
                          >
                            Download
                            <AiOutlineDownload size={16} className="btn-icon" />
                          </Button>
                          <Button
                            onClick={() =>
                              downloadAcbrLog(logFile.fileName, true)
                            }
                            title="Visualizar"
                            variant="success"
                            size="sm"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '3px',
                            }}
                          >
                            Visualizar
                            <AiFillEye size={16} className="btn-icon" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Row>
          {loading && (
            <LinearProgress
              style={{ background: '#c2a0f8', color: '#8850BF' }}
            />
          )}
          {showLogReader && (
            <Row>
              <Col
                sm={12}
                onClick={() => setShowLogReader(false)}
                style={{
                  cursor: 'pointer',
                }}
              >
                Clique para fechar o Log
              </Col>
              <Col sm={12}>
                <AceEditor
                  placeholder="Acbr Log"
                  mode="xml"
                  theme="solarized_dark"
                  name="blah2"
                  fontSize={14}
                  showPrintMargin={false}
                  showGutter={false}
                  highlightActiveLine
                  value={acbrLog}
                  readOnly
                  width="100%"
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: false,
                    tabSize: 2,
                    wrap: true,
                  }}
                />
              </Col>
            </Row>
          )}
        </Table>
      )}
      {!existeLogAcbr.logFound && (
        <Row style={{ marginTop: '20px' }}>
          <Col sm={12}>
            <Alert variant="info">
              Nenhum log encontrado para a NFe número{' '}
              <b>{getValues('num_nf')}</b>
            </Alert>
          </Col>
        </Row>
      )}
      {/* <Separator labelText="Log" sidePadding="0 0" />
      <Row>
        <Col sm={12}>
          <InputText
            label="Chave NF-e"
            maxLength={50}
            placeholder="Informe a chave"
            name="chave_nfe"
            register={register}
            disabled={isUpdate}
            isError={!!formState.errors.chave_nfe}
          />
        </Col>
        <Col sm={12} className="mt-4">
          <DataGrid
            rows={logs}
            columns={tabColumns}
            pageSize={5}
            columnBuffer={8}
            autoHeight
            disableColumnFilter
            disableColumnSelector
            disableSelectionOnClick
            localeText={{
              noRowsLabel: 'Nenhum registro encontrado',
              columnMenuLabel: 'Menu',
              columnMenuFilter: 'Filtrar',
              columnMenuHideColumn: 'Esconder',
              columnMenuUnsort: 'Não ordenar',
              columnMenuSortAsc: 'Ordernar ASC',
              columnMenuSortDesc: 'Ordernar DESC',
              columnMenuShowColumns: 'Mostrar colunas',
            }}
          />
        </Col>
      </Row> */}
    </>
  );
};
