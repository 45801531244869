import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { formatCurrencyAsText } from '~/utils/functions';
import { useEmissaoNFE } from '~/pages/EmissaoNFE/EmissaoNFEContext';
import { InputTextArea } from '~/components/NovosInputs';

export const FormFinanceiro: React.FC = () => {
  const {
    formNFE: { register, control },
    parcelasFinanceiro,
    totalFinanceiro,
    isUpdate,
    setTotalFinanceiro,
  } = useEmissaoNFE();

  useEffect(() => {
    let totalParcelas = 0;
    parcelasFinanceiro.map((parcela) => {
      totalParcelas += parcela.val_parcela || 0;
      return parcela;
    });
    setTotalFinanceiro(totalParcelas);
  }, [parcelasFinanceiro, setTotalFinanceiro]);

  return (
    <>
      <Row className="d-flex align-items-end justify-content-between">
        <Col sm={12}>
          <h5
            style={{
              textAlign: 'right',
              display: 'block',
              marginTop: '20px',
            }}
          >
            {`Total Financeiro: R$${formatCurrencyAsText(
              totalFinanceiro || 0,
            )}`}
          </h5>
        </Col>
        <Col md={12} sm={12}>
          <InputTextArea
            maxLength={500}
            label="Informação Complementar"
            name="des_info_complementar"
            register={register}
            disabled={isUpdate}
            control={control}
          />
        </Col>
      </Row>
    </>
  );
};
